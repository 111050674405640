import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ExamDayService
{
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/exam-day';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    getCompleteSearchUrl(data={},index = null) {

        let url = this.#api;
        return (url);
    }
    create(data) {
        let url = `${this.#api}`;
        return apiService.post(url, data);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }
    assignExaminer(id, data) {
        let url = `${this.#api}/${id}/assign-examiner`;
        return apiService.post(url, data)
    }
    assignSupervisor(id, data) {
        let url = `${this.#api}/${id}/assign-supervisor`;
        return apiService.post(url, data)
    }
    deleteExaminer(data) {
        let url = `${this.#api}/delete-examiner`;
        return apiService.post(url,data)
    }
    deleteSupervisor(data) {
        let url = `${this.#api}/delete-supervisor`;
        return apiService.post(url,data)
    }
    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }
    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
    all(){
        let url=`${this.#api}/get/all`;
        return apiService.get(url);
    }

    sendNotification(id, data){
        let url=`${this.#api}/${id}/notify`;
        return apiService.post(url, data);
    }

    getAccountHolderNotifications(id){
        let url=`${this.#api}/${id}/account-holder-notification`;
        return apiService.get(url);
    }

    sendNotificationToAccountHolders(id,data=null){
        let url=`${this.#api}/${id}/send-account-holder-notification`;
        return apiService.post(url,data);
    }

    sendNotificationToAccountHolder(examId,accountHolderId){
        let url=`${this.#api}/${examId}/account-holder/${accountHolderId}/send-notification`;
        return apiService.post(url);
    }

    sendNotificationToVenue(examDayId){
        let url=`${this.#api}/${examDayId}/venue/send-notification`;
        return apiService.post(url);
    }

    getExaminerNotifications(id){
        let url=`${this.#api}/${id}/examiner-notification`;
        return apiService.get(url);
    }

    sendNotificationToExaminer(examId,examinerId){
        let url=`${this.#api}/${examId}/examiner/${examinerId}/send-notification`;
        return apiService.post(url);
    }

    sendNotificationToExaminers(id,data){
        let url=`${this.#api}/${id}/send-examiner-notification`;
        return apiService.post(url,data);
    }

    getSupervisorNotifications(id){
        let url=`${this.#api}/${id}/supervisor-notification`;
        return apiService.get(url);
    }

    getByExamSession(examSessionId){
        let url=`${this.#api}/get/by/exam-session/${examSessionId}`;
        return apiService.get(url);
    }

    sendNotificationToSupervisor(examId,supervisorId){
        let url=`${this.#api}/${examId}/supervisor/${supervisorId}/send-notification`;
        return apiService.post(url);
    }

    sendNotificationToSupervisors(id,data=null){
        let url=`${this.#api}/${id}/send-supervisor-notification`;
        return apiService.post(url,data);
    }

    viewExaminerTimeTablePdf(examDayId){
        let url=`${this.#api}/${examDayId}/examiner-timetable`;
        window.open(url, '_blank');
    }

    viewSupervisorTimeTablePdf(examDayId){
        let url=`${this.#api}/${examDayId}/supervisor-timetable`;
        window.open(url, '_blank');
    }

    doAskExaminerAvailability(data){
        let url = `${this.#api}/do-ask/examiner/confirmation`;
        return apiService.post(url, data);
    }

    doAskSupervisorAvailability(data){
        let url = `${this.#api}/do-ask/supervisor/confirmation`;
        return apiService.post(url, data);
    }

    getExaminerConfirmations(examDayId){
        let url=`${this.#api}/${examDayId}/examiner-confirmation`;
        return apiService.get(url);
    }

    getSupervisorConfirmations(examDayId){
        let url=`${this.#api}/${examDayId}/supervisor-confirmation`;
        return apiService.get(url);
    }

    sendVenueMail(examDayId){
        let url=`${this.#api}/${examDayId}/venue/confirmation`;
        return apiService.post(url);
    }

    getPendingTimetableLists(data={},index = null) {
        let url = `${this.#api}/pending-timetable/get`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getAccountHolderNotificationFields(examDayId){
        let url=`${this.#api}/account-holder-notification-fields/get`;
        return apiService.get(url);
    }

    getNotScheduledCandidates(examDayId)
    {
        let url=`${this.#api}/account-holder-notification-fields/get`;
        return apiService.get(url);
    }

    getTimetableAssignedCandidates(examDayId)
    {
        let url=`${this.#api}/get/${examDayId}/timetable-assigned/candidates`;
        return apiService.get(url);
    }

    getTimetableNotAssignedCandidates(examSessionId,data)
    {
        let url=`${this.#api}/get/${examSessionId}/timetable-not-assigned/candidates`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getBreaks()
    {
        let url=`${this.#api}/get-breaks`;
        return apiService.get(url);
    }

    setCandidateToIndividualOrGroup(data){
        let url=`${this.#api}/candidate/set/individual-or-group`;
        return apiService.post(url,data);
    }


    setCandidateToLockedOrUnlocked(data){
        let url=`${this.#api}/candidate/set/locked-or-unlocked`;
        return apiService.post(url,data);
    }

    removeCandidateAndSaveEmptySlot(data){
        let url=`${this.#api}/remove-candidate/save-empty-slot`;
        return apiService.post(url,data);
    }

    saveCandidateTimetable(data){
        let url=`${this.#api}/save-candidate/timetable`;
        return apiService.post(url,data);
    }

    markAsComplete(examDayId){
        let url=`${this.#api}/${examDayId}/mark-as-complete`;
        return apiService.post(url);
    }

    deleteBreak(id)
    {
        let url=`${this.#api}/${id}/break/delete`;
        return apiService.delete(url);
    }

    checkExaminerAvailability(data){
        let url=`${this.#api}/check/examiner-availability`;
        return apiService.post(url,data);
    }
    checkSupervisorAvailability(data){
        let url=`${this.#api}/check/supervisor-availability`;
        return apiService.post(url,data);
    }

    saveAndConfirm(examDayId){
        let url=`${this.#api}/${examDayId}/save-and-confirm`;
        return apiService.post(url);
    }

    getCandidates(data={},index = null) {
        let url = `${this.#api}/get/candidates`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

    getExportFilters(){
        let url = `${this.#api}/search-parameters/get`;
        return apiService.get(url);
    }

    // acholder export 
    exportToCsv(data = {}){
        let url = `${this.#api}/export/csv`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }
    
    exportToExcel(data = {}){
        let url = `${this.#api}/export/excel`;
        if (data != null && data != "") {
            let queryString = Object.keys(data).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&');
            url = url + '?' + queryString;
        }
        return apiService.get(url);
    }

    bulkAction(data)
    {
        let url=`${this.#api}/bulk-actions`;
        return apiService.post(url,data);
    }

    updateChiefAndTraineeExaminer(data){
        let url=`${this.#api}/update/trainee-and-chief-examiner`;
        return apiService.post(url,data);
    }

    markReportReceived(examDayId){
        let url=`${this.#api}/${examDayId}/mark/report-received`;
        return apiService.post(url);
    }

    markReportNotReceived(examDayId){
        let url=`${this.#api}/${examDayId}/mark/report-not-received`;
        return apiService.post(url);
    }
    countFilteredExamDays(data){
        let url = `${this.#api}/count-by-filters`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }

}